.nft-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.image-container {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 220px;
}

.image {
  width: 100%;
  height: auto;
}
.nft-gallery {
  background-color: #000;
}
body {
  background-color: #000;
  color: #fff;
}
.image-container {
  position: relative;
}

.image-container p {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 10px;
}
.gallery-title, .gallery-description {
  text-align: center;
}
